// This is the place to place Bootstrap-related CSS customizations.
// As example you can start by importing the _bootswatch.scss file from one of the
// theme provided by https://bootswatch.com .

// possibly formerly Minty 4.3.1
// Bootswatch

// Variables ===================================================================

$web-font-path: "https://fonts.bunny.net/css?family=petrona:400,700|jetbrains-mono:400,700|inter:400" !default;
@import url($web-font-path);

// Navbar ======================================================================

.navbar {
    font-family: $headings-font-family;
}

.bg-dark {
    background-color: $primary !important;
}

// Buttons =====================================================================

.btn {
    font-family: $headings-font-family;

    &,
    &:hover {
        color: $white;
    }

    &-light,
    &-light:hover {
        color: $gray-700;
    }

    &-link,
    &-link:hover {
        color: $primary;
    }

    &-link.disabled:hover {
        color: $gray-600;
    }

    &-outline-primary {
        color: $primary;
    }

    &-outline-secondary {
        color: $secondary;
    }

    &-outline-success {
        color: $success;
    }

    &-outline-info {
        color: $info;
    }

    &-outline-warning {
        color: $warning;
    }

    &-outline-danger {
        color: $danger;
    }

    &-outline-dark {
        color: $dark;
    }

    &-outline-light {
        color: $light;
    }
}

// Typography ==================================================================

// Tables ======================================================================

.table {

    &-primary,
    &-secondary,
    &-success,
    &-info,
    &-warning,
    &-danger {
        color: #fff;
    }

    &-primary {
        &, > th, > td {
            background-color: $primary;
        }
    }

    &-secondary {
        &, > th, > td {
            background-color: $secondary;
        }
    }

    &-light {
        &, > th, > td {
            background-color: $light;
        }
    }

    &-dark {
        &, > th, > td {
            background-color: $dark;
        }
    }

    &-success {
        &, > th, > td {
            background-color: $success;
        }
    }

    &-info {
        &, > th, > td {
            background-color: $info;
        }
    }

    &-danger {
        &, > th, > td {
            background-color: $danger;
        }
    }

    &-warning {
        &, > th, > td {
            background-color: $warning;
        }
    }

    &-active {
        &, > th, > td {
            background-color: $table-active-bg;
        }
    }

    &-hover {

        .table-primary:hover {
            &, > th, > td {
                background-color: darken($primary, 5%);
            }
        }

        .table-secondary:hover {
            &, > th, > td {
                background-color: darken($secondary, 5%);
            }
        }

        .table-light:hover {
            &, > th, > td {
                background-color: darken($light, 5%);
            }
        }

        .table-dark:hover {
            &, > th, > td {
                background-color: darken($dark, 5%);
            }
        }

        .table-success:hover {
            &, > th, > td {
                background-color: darken($success, 5%);
            }
        }

        .table-info:hover {
            &, > th, > td {
                background-color: darken($info, 5%);
            }
        }

        .table-danger:hover {
            &, > th, > td {
                background-color: darken($danger, 5%);
            }
        }

        .table-warning:hover {
            &, > th, > td {
                background-color: darken($warning, 5%);
            }
        }

        .table-active:hover {
            &, > th, > td {
                background-color: $table-active-bg;
            }
        }
    }

    .thead-dark th {
        background-color: $primary;
        border-color: $table-border-color;
        font-family: $headings-font-family;
    }
}

// Forms =======================================================================

legend {
    font-family: $headings-font-family;
}

// Navs ========================================================================

.dropdown-menu {
    font-family: $font-family-sans-serif;
}

.breadcrumb {
    a {
        color: $navbar-dark-color;
    }

    a:hover {
        color: $white;
        text-decoration: none;
    }
}

// Indicators ==================================================================

.alert {
    color: $white;

    h1, h2, h3, h4, h5, h6 {
        color: inherit;
    }

    a,
    .alert-link {
        color: $white;
    }

    &-primary {
        &, > th, > td {
            background-color: $primary;
        }
    }

    &-secondary {
        &, > th, > td {
            background-color: $secondary;
        }
    }

    &-success {
        &, > th, > td {
            background-color: $success;
        }
    }

    &-info {
        &, > th, > td {
            background-color: $info;
        }
    }

    &-danger {
        &, > th, > td {
            background-color: $danger;
        }
    }

    &-warning {
        &, > th, > td {
            background-color: $warning;
        }
    }

    &-dark {
        &, > th, > td {
            background-color: $dark;
        }
    }

    &-light {
        &, > th, > td {
            background-color: $light;
        }
    }

    &-light {
        &,
        & a:not(.btn),
        & .alert-link {
            color: $body-color;
        }
    }
}

.badge {
    color: $white;

    &-light {
        color: $gray-700;
    }
}

// Progress bars ===============================================================

// Containers ==================================================================

.card,
.list-group-item {
    h1, h2, h3, h4, h5, h6 {
        color: inherit;
    }
}

.logo-icon {
    color: $gray-800;
}

.bg-primary {
    color: $white;
}


.bg-primary a {
    color: $link-color;
    text-decoration: $link-decoration;
    background-color: transparent; // Remove the gray background on active links in IE 10.

    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}

// Plain CSS
#footer {
    position: fixed;
    transition: bottom 0.3s ease-in 0s;
    bottom: -96px;
}

#footer:hover {
    bottom: -6px;
}

.hyphenate {
    hyphens: auto;
}

.weather-icon {
    border: 1px solid $neutral-900;
}

aside {
    box-shadow: inset 5px 0 5px -5px $primary;
    color: $primary;
    float: right;
    font-style: italic;
    margin-bottom: .5rem;
    margin-left: .5rem;
    margin-top: .5rem;
    padding-left: 1rem;
    width: 40%;
}

aside > figure > p {
    margin: .5rem;
    font-family: 'Fira Sans', sans-serif;
}

.rouge-table > tbody > tr {
    vertical-align: top;
}

.lineno {
    color: #444;
    background-color: $slate-200;
    display: inline-block;
    padding: 0 5px;
    border-right: 1px solid #ccc;
    -webkit-touch-callout: none;
    user-select: none;
}

.highlighter-rouge {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.section  {
    h1, h2, h3, h4, h5, h6 {
        margin-top: 1.5rem;
    }
}

.slider {
  line-height: 1.4;
}
