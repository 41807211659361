// This is the place to place Bootstrap's variables overrides.
// As example you can start by importing the _variables.scss file from one of the
// theme provided by https://bootswatch.com .

// Some customization of the container class to have a better display in the guide.
// Don't hesitate to remove it to have a standard Bootstrap behavior

// formerly Minty 4.3.1
// Bootswatch

//
// Color system
//
@import url(https://use.fontawesome.com/releases/v5.0.10/css/all.css);

$gray-50: #F9FAFB;
$gray-100: #F3F4F6;
$gray-200: #E5E7EB;
$gray-300: #D1D5DB;
$gray-400: #9CA3AF;
$gray-500: #6B7280;
$gray-600: #4B5563;
$gray-700: #374151;
$gray-800: #1F2937;
$gray-900: #111827;

$amber-500: #F59E0B;
$neutral-900: #171717;
$slate-50: #F8FAFC;
$slate-100: #F1F5F9;
$slate-200: #E2E8F0;
$slate-300: #CBD5E1;
$slate-400: #94A3B8;
$slate-500: #64748B;
$slate-600: #475569;
$slate-700: #334155;
$slate-800: #1E293B;
$slate-900: #0F172A;
$sky-700: #0369A1;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #FF0;
$red: #FF7851;
$orange: #fd7e14;
$yellow: #FFCE67;
$green: #56CC9D;
$teal: #20c997;
$cyan: #6CC3D5;
$white: #FFFFFF;
$black: $neutral-900;

$primary: $slate-700;
$secondary: $amber-500;
$success: #065b51;
$danger: #b82f3a;
$info: #80a8c1;
$warning: #a29f00;
$light: #d6d6d6;
$dark: #4e4e4e;

$yiq-contrasted-threshold: 250;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-gradients: false;
$enable-shadows: true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.2rem;

$spacers: (
        6: ($spacer * 12)
);

// Body
$body-bg: $white;
$body-color: $primary;

$code-color: $slate-500;

// Links
//
// Style anchor elements.

$link-color: $sky-700; // darken($red, 15%);
$link-decoration: none;
$link-hover-color: rgba($link-color, .3); // lighten($link-color, 80%);
$link-hover-decoration: underline;

$navbar-dark-color: rgba($info, .6);
$navbar-dark-hover-color: $info;

$navbar-light-color: rgba($black, .3);
$navbar-light-hover-color: $gray-700;
$navbar-light-active-color: $gray-700;
$navbar-light-disabled-color: rgba($black, .1);

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$line-height-base: 1.8;

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       'JetBrains Mono', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-border-color: rgba(0, 0, 0, 0.05);

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-border-radius: .25rem;

// Components

$border-radius: .4rem;
$border-radius-lg: .6rem;
$border-radius-sm: .3rem;
$border-width: 2px;

$headings-font-family: Petrona;
$headings-color: $gray-700;

// Dropdowns

$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $secondary;

// Navbar
$navbar-dark-color: rgba($white, .6);
$navbar-dark-hover-color: $white;

$navbar-light-color: rgba($black, .3);
$navbar-light-hover-color: $gray-700;
$navbar-light-active-color: $gray-700;
$navbar-light-disabled-color: rgba($black, .1);

// Pagination

$pagination-color: $white;
$pagination-bg: $primary;
$pagination-border-color: $primary;

$pagination-hover-color: $white;
$pagination-hover-bg: $secondary;
$pagination-hover-border-color: $pagination-hover-bg;

$pagination-active-bg: $secondary;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $white;
$pagination-disabled-bg: #CCE8E0;
$pagination-disabled-border-color: $pagination-disabled-bg;

// Breadcrumbs

$breadcrumb-bg: $primary;
$breadcrumb-divider-color: $white;
$breadcrumb-active-color: $breadcrumb-divider-color;

// Card
$card-bg: $slate-50;
$card-border-color: $slate-200;
$card-cap-bg: $slate-100;
